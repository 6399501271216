import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import moment from 'moment';
import 'utils/momentLocales';
import { mapLocaleToMoment } from 'constants.js';
import TopBarProgress from 'react-topbar-progress-indicator';
import momentLocalizer from 'react-widgets-moment';
import ReactTooltip from 'react-tooltip';
import 'react-widgets/dist/css/react-widgets.css';
import isNumber from 'lodash/isNumber';

import './index.css';
import App from './App';
import { getTranslations } from './localeContext';
import { store, userActions } from './store';
import history from './history';
import { getByTokenSuccess } from './store/user/actions';
import { apiToken } from './utils/Api/Request';
import { CURRENT_LOCALE_KEY } from './utils/lang';
import { client } from './utils';

TopBarProgress.config({
    barColors: {
        0: '#FFFFFF',
        '1.0': '#4D4D4D',
    },
    shadowBlur: 0,
});

moment.locale('en');
momentLocalizer();

const renderApp = () => {
    createRoot(document.getElementById('react-root')).render(
        <Router history={history}>
            <Provider store={store}>
                <Suspense fallback={<TopBarProgress />}>
                    <App />
                </Suspense>
            </Provider>
        </Router>
    );
};

window.setReactUser = async data => {
    if (data) {
        moment.locale(mapLocaleToMoment(data.displayLanguage.language.code));
        localStorage.setItem(CURRENT_LOCALE_KEY, data.displayLanguage.language.code);
        await getTranslations();
    }
    store.dispatch(getByTokenSuccess(data));
};

const getAppClient = async () => {
    // Don't need this call because angular app do it for us.
    // Roll back after Angular acll for partnermarketing_client_get_by_subdomain_slug_api
    // will be removed
    // Wait until angular loading the client.
    await new Promise(resolve => {
        const clientIntervalId = setInterval(() => {
            if (isNumber(client.getId())) {
                clearInterval(clientIntervalId);
                resolve();
            }
        }, 100);
    });

    if (apiToken.getUser()) {
        await store.dispatch(userActions.getByToken());
        await getTranslations();
    }

    renderApp();
};

getAppClient();

// Add the logic to sync locations in react and angular apps.
history.listen(location => {
    ReactTooltip.hide();

    // TODO: replace it with useLocation.
    store.dispatch(userActions.setPathName(location.pathname));

    const injector = window.angular.element(document).injector();
    const $location = injector.get('$location');
    const $rootScope = injector.get('$rootScope');

    if (location.pathname !== $location.path()) {
        console.log('pre-apply', location.pathname, $location.path());
        $location.url(`${location.pathname}${location.search || ''}`);
        $rootScope.reactHistoryState = location.state;
        // Need to call $apply to allow $location sync the watchers/observers.
        $rootScope.$apply();
    }
});

window.addEventListener(
    'message',
    event => {
        if (typeof event.data === 'object' && event.data.call === 'angularDataSync') {
            // eslint-disable-next-line
            console.log('[react] received angularDataSync', event.data);

            store.dispatch(userActions[event.data.action](event.data.data));
        }
    },
    false
);
