import Quill from 'quill';
import React from 'react';
import { t } from 'utils';

type ShortCode = {
    label: string;
    value: string;
};

const generalCodes: ShortCode[] = [
    { label: '[Partner name]', value: '[[PARTNER_NAME]]' },
    { label: '[Partner email]', value: '[[PARTNER_EMAIL]]' },
    { label: '[Partner telephone]', value: '[[PARTNER_TELEPHONE]]' },
    { label: '[Partner URL]', value: '[[PARTNER_URL]]' },
    { label: '[Partner address]', value: '[[PARTNER_ADDRESS]]' },
    { label: '[Value proposition]', value: '[[VALUEPROPOSITION]]' },
];

const emailCodes: ShortCode[] = [
    { label: '[Recipient email]', value: '[[EMAIL_TO]]' },
    { label: '[Recipient first name]', value: '[[RECIPIENT_FNAME]]' },
    { label: '[Recipient last name]', value: '[[RECIPIENT_LNAME]]' },
];

type ShortCodesSelectProps = {
    isEmail?: boolean;
};

export default function ShortCodesSelect({ isEmail = false }: ShortCodesSelectProps): JSX.Element {
    const codes = [...generalCodes, ...(isEmail ? emailCodes : [])];
    return (
        <select className="ql-short-codes" defaultValue="">
            <option value="">{t('general.labels.short_codes')}</option>
            {codes.map(({ label, value }) => (
                <option value={value} key={value}>
                    {label}
                </option>
            ))}
        </select>
    );
}

export function shortCodesHandler(this: { quill: Quill }, value: string): void {
    const range = this.quill.getSelection();
    const position = range ? range.index : 0;
    this.quill.insertText(position, value);
}
