import { TB_ROUTES } from 'apps/template-builder/constants';

export const ROUTE = {
    home: '/',
    logout: '/logout',
    account: {
        root: '/account',
        details: '/account/details',
        company: '/account/company',
        logo: '/account/logo',
        password: '/account/password',
        settings: '/account/settings',
        social: '/account/social',
        userManagement: '/account/user-management',
    },
    basket: {
        root: '/basket',
    },
    loyaltyProgram: {
        root: '/rewards-club',
        program(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/program`;
        },
        basket(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/basket`;
        },
        rewardRequest(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/reward-request`;
        },
        rewardRequestThanks(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/reward-request-thanks`;
        },
        signUp(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/sign-up`;
        },
        terms(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/terms`;
        },
        eligibleProducts(code = ':code', tab = ':tab') {
            return `${ROUTE.loyaltyProgram.root}/${code}/eligible-products/${tab}`;
        },
        howToEarn(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/how-to-earn`;
        },
        resources(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/resources`;
        },
        catalogueRoot(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/catalogue`;
        },
        catalogue(code = ':code', tab = ':tab') {
            return `${ROUTE.loyaltyProgram.root}/${code}/catalogue/${tab}`;
        },
        viewProgram(code = ':code') {
            return `${ROUTE.loyaltyProgram.root}/${code}/view-program`;
        },
        statement: {
            root(code = ':code') {
                return `${ROUTE.loyaltyProgram.root}/${code}/statement`;
            },
            view(code = ':code', tab = ':tab') {
                return `${ROUTE.loyaltyProgram.statement.root(code)}/${tab}`;
            },
            individualList: {
                root(code = ':code', tab = ':tab') {
                    return `${ROUTE.loyaltyProgram.statement.root(code)}/${tab}/individual-accounts`;
                },
                view(code = ':code', tab = ':tab', user = ':user') {
                    return `${ROUTE.loyaltyProgram.statement.root(code)}/${tab}/individual-accounts/${user}`;
                },
                add(code = ':code', tab = ':tab') {
                    return `${ROUTE.loyaltyProgram.statement.individualList.root(code, tab)}/add`;
                },
            },
            individual(code = ':code', tab = ':tab') {
                return `${ROUTE.loyaltyProgram.statement.root(code)}/${tab}/individual`;
            },
            transferPoints(code = ':code', tab = ':tab', userId = '') {
                const search = userId ? `?active=${userId}` : '';
                return `${ROUTE.loyaltyProgram.statement.individualList.root(code, tab)}/transfer-points${search}`;
            },
            transferPointsSuccess(code = ':code', tab = ':tab') {
                return `${ROUTE.loyaltyProgram.statement.transferPoints(code, tab)}/success`;
            },
        },
    },
    spiff: {
        root: '/spiff',
        application: {
            get root() {
                return `${ROUTE.spiff.root}/application`;
            },
            get status() {
                return `${ROUTE.spiff.application.root}/status`;
            },
            createPlan: {
                get root() {
                    return `${ROUTE.spiff.application.root}/create-plan`;
                },
                get step1() {
                    return `${ROUTE.spiff.application.createPlan.root}/step1`;
                },
                get step2() {
                    return `${ROUTE.spiff.application.createPlan.root}/step2`;
                },
                get address() {
                    return `${ROUTE.spiff.application.createPlan.root}/address`;
                },
                get review() {
                    return `${ROUTE.spiff.application.createPlan.root}/review`;
                },
            },
            createClaim: {
                get root() {
                    return `${ROUTE.spiff.application.root}/create-claim`;
                },
                get form() {
                    return `${ROUTE.spiff.application.createClaim.root}/form`;
                },
                get review() {
                    return `${ROUTE.spiff.application.createClaim.root}/review`;
                },
            },
            plan: {
                root(id = ':id') {
                    return `${ROUTE.spiff.application.root}/plan/${id}`;
                },
                step1(id = ':id') {
                    return `${ROUTE.spiff.application.plan.root(id)}/step1`;
                },
                step2(id = ':id') {
                    return `${ROUTE.spiff.application.plan.root(id)}/step2`;
                },
                review(id = ':id') {
                    return `${ROUTE.spiff.application.plan.root(id)}/review`;
                },
                view(id = ':id') {
                    return `${ROUTE.spiff.application.plan.root(id)}/view`;
                },
            },
            claim: {
                root(id = ':id') {
                    return `${ROUTE.spiff.application.root}/claim/${id}`;
                },
                form(id = ':id') {
                    return `${ROUTE.spiff.application.claim.root(id)}/form`;
                },
                review(id = ':id') {
                    return `${ROUTE.spiff.application.claim.root(id)}/review`;
                },
                view(id = ':id') {
                    return `${ROUTE.spiff.application.claim.root(id)}/view`;
                },
            },
        },
        audit: {
            root(type = ':type', id = ':id') {
                return `${ROUTE.spiff.root}/audit/${type}/${id}`;
            },
            plan(id = ':id') {
                return ROUTE.spiff.audit.root('plan', id);
            },
            claim(id = ':id') {
                return ROUTE.spiff.audit.root('claim', id);
            },
        },
        get applicationsRoot() {
            return `${ROUTE.spiff.root}/applications`;
        },
        applications(type = ':type', tab = ':tab') {
            return `${ROUTE.spiff.applicationsRoot}/${type}/${tab}`;
        },
        report: {
            get root() {
                return `${ROUTE.spiff.root}/report`;
            },
            list(type = ':type', tab = ':tab') {
                return `${ROUTE.spiff.report.root}/${type}/${tab}`;
            },
            view(type = ':type', id = ':id') {
                return `${ROUTE.spiff.report.root}/${type}/${id}/view`;
            },
            plan(id = ':id') {
                return ROUTE.spiff.report.view('plan', id);
            },
            claim(id = ':id') {
                return ROUTE.spiff.report.view('claim', id);
            },
        },
    },
    salesResources: {
        root: '/sales-resources',
        byStatus(status = ':status') {
            return `${ROUTE.salesResources.root}/status/${status}`;
        },
        email: {
            get root() {
                return `${ROUTE.salesResources.root}/email`;
            },
            step1: (id = ':id') => `${ROUTE.salesResources.email.root}/${id}/step1`,
            step2: (id = ':id') => `${ROUTE.salesResources.email.root}/${id}/step2`,
            step2Confirm: (id = ':id') => `${ROUTE.salesResources.email.root}/${id}/step2/confirm`,
            sendTest: (id = ':id') => `${ROUTE.salesResources.email.root}/${id}/send-test`,
            sendTestSuccess: (id = ':id') => `${ROUTE.salesResources.email.root}/${id}/send-test/success`,
        },
        resource: {
            get root() {
                return `${ROUTE.salesResources.root}/resource`;
            },
            get create() {
                return `${ROUTE.salesResources.resource.root}/create`;
            },
            createSuccess: (id = ':id') => `${ROUTE.salesResources.resource.root}/${id}/created`,
            report: (id = ':id') => `${ROUTE.salesResources.resource.root}/${id}/report`,
        },
    },
    asset: {
        root: '/asset',
        request: {
            get root() {
                return `${ROUTE.asset.root}/request`;
            },
            get step1() {
                return `${ROUTE.asset.request.root}/step1`;
            },
            get step2() {
                return `${ROUTE.asset.request.root}/step2`;
            },
            view(id = ':id') {
                return `${ROUTE.asset.request.root}/view/${id}`;
            },
            get myRequests() {
                return `${ROUTE.asset.root}/requests`;
            },
        },
    },
    assetLibrary: {
        root: '/asset-library',
        browse: {
            get root() {
                return `${ROUTE.assetLibrary.root}/browse`;
            },
            get list() {
                return `${ROUTE.assetLibrary.browse.root}/search`;
            },
            listByType(type = '') {
                const search = type ? `?type=${type}` : '';
                return `${ROUTE.assetLibrary.browse.root}/search${search}`;
            },
            asset: (assetId = ':assetId') => `${ROUTE.assetLibrary.browse.root}/asset/${assetId}`,
            code: (assetId = ':assetId') => `${ROUTE.assetLibrary.browse.root}/asset/${assetId}/code`,
            link: (id = ':assetId', hash = ':hash') => `${ROUTE.assetLibrary.browse.root}/asset/${id}/link/${hash}`,
        },
        setUp: {
            get root() {
                return `${ROUTE.assetLibrary.root}/set-up`;
            },
            assets: {
                get root() {
                    return `${ROUTE.assetLibrary.setUp.root}/assets`;
                },
                get listRoot() {
                    return `${ROUTE.assetLibrary.setUp.root}/list`;
                },
                list: (status = ':status?') => `${ROUTE.assetLibrary.setUp.assets.listRoot}/${status}`,
                get create() {
                    return `${ROUTE.assetLibrary.setUp.assets.root}/create`;
                },
                history: (id = ':id') => `${ROUTE.assetLibrary.setUp.assets.root}/history/${id}`,
                update: (id = ':id') => `${ROUTE.assetLibrary.setUp.assets.root}/update/${id}`,
            },
        },
    },
    campaignBuilderSetup: {
        groups: '/settings/groups',
    },
    marketingStore: {
        root: '/marketing-store',
        activity(id = ':id') {
            return `${ROUTE.marketingStore.root}/activity/${id}`;
        },
        campaign(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.marketingStore.root}/campaign/${campaignId}/${languageId}`;
        },
        get setupActivities() {
            return `${ROUTE.marketingStore.root}/set-up/activities`;
        },
    },
    createCampaign: {
        root: '/create-campaign/',
        view(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.createCampaign.root}${campaignId}/${languageId}/view`;
        },
        useCoBranded(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.createCampaign.root}${campaignId}/${languageId}/co-branded`;
        },
        optional(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.createCampaign.root}${campaignId}/${languageId}/optional`;
        },
        activities(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.createCampaign.root}${campaignId}/${languageId}/activities`;
        },
        byView(view = ':view') {
            return `${ROUTE.createCampaign.root}view-by/${view}`;
        },
    },
    campaignInstances: {
        root: '/campaigns',
        get list() {
            return `${ROUTE.campaignInstances.root}/list/`;
        },
        byState(state = ':state') {
            return `${ROUTE.campaignInstances.list}${state}`;
        },
        publication(id = ':id') {
            return `/campaign/create/${id}/publication`;
        },
    },
    thenCreateCampaign: {
        root: '/campaign-details/then-create-campaign/',
        name(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.thenCreateCampaign.root}${campaignId}/${languageId}/name`;
        },
        value(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.thenCreateCampaign.root}${campaignId}/${languageId}/value`;
        },
        landingPage(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.thenCreateCampaign.root}${campaignId}/${languageId}/landing-page`;
        },
        personalDetails(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.thenCreateCampaign.root}${campaignId}/${languageId}/personal-details`;
        },
        companyDetails(campaignId = ':campaignId', languageId = ':languageId') {
            return `${ROUTE.thenCreateCampaign.root}${campaignId}/${languageId}/company-details`;
        },
    },
    activities: {
        root: '/activities',
        get list() {
            return `${ROUTE.activities.root}/list/`;
        },
        byState(state = ':state') {
            return `${ROUTE.activities.list}${state}`;
        },
        view(id = ':id') {
            return `${ROUTE.activities.root}/view/${id}`;
        },
        formReport(id = ':id', packageId = ':packageId') {
            return `${ROUTE.activities.root}/form-report/${id}/${packageId}`;
        },
    },
    leads: {
        root: '/leads',
        byStatus(status = ':status') {
            return `${ROUTE.leads.root}/status/${status}`;
        },
        edit(id = ':id') {
            return `/lead/${id}`;
        },
    },
    onboarding: {
        root: '/onboarding',
        step(number = ':number') {
            return `${ROUTE.onboarding.root}/step/${number}`;
        },
        get completed() {
            return `${ROUTE.onboarding.root}/completed`;
        },
    },
    lead: {
        root: '/lead',
        list: {
            get root() {
                return `${ROUTE.lead.root}/list`;
            },
            myLeads: {
                get root() {
                    return `${ROUTE.lead.root}/my-leads`;
                },
                byStatus(status = ':status') {
                    return `${ROUTE.lead.list.myLeads.root}/status/${status}`;
                },
                step1: (id = ':id') => `${ROUTE.lead.list.myLeads.root}/${id}/step1`,
                step2: (id = ':id') => `${ROUTE.lead.list.myLeads.root}/${id}/step2`,
                step2Confirm: (id = ':id') => `${ROUTE.lead.list.myLeads.root}/${id}/step2/confirm`,
                sendTest: (id = ':id') => `${ROUTE.lead.list.myLeads.root}/${id}/send-test`,
                sendTestSuccess: (id = ':id') => `${ROUTE.lead.list.myLeads.root}/${id}/send-test/success`,
            },
            create: {
                get root() {
                    return `${ROUTE.lead.list.root}/create`;
                },
                get confirmRules() {
                    return `${ROUTE.lead.list.create.root}/confirm-rules`;
                },
                get confirmPage() {
                    return `${ROUTE.lead.list.create.root}/confirm-page`;
                },
                get progress() {
                    return `${ROUTE.lead.list.create.root}/progress`;
                },
                get final() {
                    return `${ROUTE.lead.list.create.root}/final`;
                },
            },
            add_leads(id = ':id') {
                return `${ROUTE.lead.list.root}/add-leads/${id}`;
            },
            byStatus(status = ':status') {
                return `${ROUTE.lead.list.root}/status/${status}`;
            },
            edit(id = ':id') {
                return `${ROUTE.lead.list.root}/${id}`;
            },
        },
        edit: (id = ':id') => `${ROUTE.lead.root}/edit/${id}`,
    },
    identifierBuilder: '/identifier-builder',
    incentive: {
        root: '/incentive',
        get howToEarn() {
            return `${ROUTE.incentive.root}/how-to-earn`;
        },
        get prizes() {
            return `${ROUTE.incentive.root}/prizes`;
        },
        byPrice(price = ':price') {
            return `${ROUTE.incentive.root}/price/${price}`;
        },
        get basket() {
            return `${ROUTE.incentive.root}/basket`;
        },
        get rewardRequest() {
            return `${ROUTE.incentive.root}/reward-request`;
        },
        get rewardRequestThanks() {
            return `${ROUTE.incentive.root}/reward-request-thanks`;
        },
    },
    // "Legacy" paths for dedicated Opportunity Module Flow
    opportunity: {
        root: '/opportunity',
        get list() {
            return `${ROUTE.opportunity.root}/list`;
        },
        listStatus: (status = ':status') => `${ROUTE.opportunity.root}/list/${status}`,
        view: (id = ':id') => `${ROUTE.opportunity.root}/view/${id}`,
    },
    // "Legacy" paths for dedicated Opportunity Module Flow
    partnerLead: {
        root: '/partner-lead',
        get create() {
            return `${ROUTE.partnerLead.root}/create`;
        },
        get thankYou() {
            return `${ROUTE.partnerLead.root}/thank-you`;
        },
    },
    // Active paths for standard Opportunity Module Flow
    opportunities: {
        root: '/opportunities',
        get list() {
            return `${ROUTE.opportunities.root}`;
        },
        get register() {
            return `${ROUTE.opportunities.root}/register-new`;
        },
        edit: (id = ':id') => `${ROUTE.opportunities.root}/edit/${id}`,
        registerLead: (leadId = ':leadId') => `${ROUTE.opportunities.root}/register-lead/${leadId}`,
        get registerFinish() {
            return `${ROUTE.opportunities.root}/register-new-finish`;
        },
        get registerThanks() {
            return `${ROUTE.opportunities.root}/register-thanks`;
        },
    },
    reporting: {
        root: '/reports',
        marketingStore: {
            get root() {
                return `${ROUTE.reporting.root}/marketing-store`;
            },
            view(id = ':id') {
                return `${ROUTE.reporting.marketingStore.root}/view/${id}`;
            },
        },
        partner: {
            get root() {
                return `${ROUTE.reporting.root}/partner`;
            },
            campaignLandingPage(campaignId = ':campaignId', templateId = ':templateId') {
                return `${ROUTE.reporting.partner.root}/campaign/${campaignId}/landing-page-new/${templateId}`;
            },
            socialNetworkReport(campaignInstanceId = ':campaignInstanceId', templateId = ':templateId') {
                return `${ROUTE.reporting.partner.root}/campaign/${campaignInstanceId}/social-network/${templateId}`;
            },
        },
        vendor: {
            get root() {
                return `${ROUTE.reporting.root}/vendor`;
            },
            get opportunityLeads() {
                return `${ROUTE.reporting.vendor.root}/opportunity-leads`;
            },
        },
        vendorPartnersReport: '/reports/vendor/partners',
        vendorPartnersParticipationReport: '/reports/vendor/participation',
        vendorCampaignsReport: '/reports/vendor/campaigns',
        partnerLandingPageTemplateReport(campaignInstanceId = ':campaignInstanceId', templateId = ':templateId') {
            return `/reports/partner/campaign/${campaignInstanceId}/landing-page/${templateId}`;
        },
        partnerEmailTemplateReport(campaignInstanceId = ':campaignInstanceId', templateId = ':templateId') {
            return `/reports/partner/campaign/${campaignInstanceId}/email/${templateId}`;
        },
        partnerStaticBannerReport(campaignInstanceId = ':campaignInstanceId', templateId = ':templateId') {
            return `/reports/partner/campaign/${campaignInstanceId}/banner/${templateId}`;
        },
        partnerAnimatedBannerReport(campaignInstanceId = ':campaignInstanceId', templateId = ':templateId') {
            return `/reports/partner/campaign/${campaignInstanceId}/animated-banner/${templateId}`;
        },
    },
    socialPosts: {
        root: '/social_post',
        get create() {
            return `${ROUTE.socialPosts.root}/create`;
        },
        createFrom(id = ':id') {
            return `${ROUTE.socialPosts.root}/from/${id}`;
        },
        createFromCode(code = ':code') {
            return `${ROUTE.socialPosts.root}/from_code/${code}`;
        },
        edit(id = ':id') {
            return `${ROUTE.socialPosts.root}/edit/${id}`;
        },
        publish(id = ':id') {
            return `${ROUTE.socialPosts.root}/publish/${id}`;
        },
        get list() {
            return `${ROUTE.socialPosts.root}/list`;
        },
        byState(status = ':status') {
            return `${ROUTE.socialPosts.list}/${status}`;
        },
        view(id = ':id') {
            return `${ROUTE.socialPosts.root}/view/${id}`;
        },
        report(id = ':id') {
            return `${ROUTE.socialPosts.root}/report/${id}`;
        },
    },
    campaigns: '/activities',
    campaign: {
        root: '/campaign',
        details(id = ':id') {
            return `${ROUTE.campaign.root}/${id}/details`;
        },
        create: {
            get root() {
                return `${ROUTE.campaign.root}/create`;
            },
            publication(id = ':id') {
                return `${ROUTE.campaign.create.root}/${id}/publication`;
            },
            schedule: (id, templateId) => `${ROUTE.campaign.create.publication(id)}/schedule/${templateId}`,
            templates(id = ':id') {
                return `${ROUTE.campaign.create.root}/${id}/templates`;
            },
            customiseTemplate(id = ':id', templateId = ':templateId') {
                return `${ROUTE.campaign.create.root}/${id}/template/${templateId}`;
            },
            customiseTemplateOld(id = ':id', templateId = ':templateId') {
                return `${ROUTE.campaign.create.root}/${id}/template-old/${templateId}`;
            },
        },
    },
    campaignDetails: {
        root: '/campaign-details',
        edit: {
            get root() {
                return `${ROUTE.campaignDetails.root}/edit`;
            },
            value(id = ':campaignInstanceId') {
                return `${ROUTE.campaignDetails.edit.root}/${id}/value`;
            },
            name(id = ':campaignInstanceId') {
                return `${ROUTE.campaignDetails.edit.root}/${id}/name`;
            },
            landingPage(id = ':campaignInstanceId') {
                return `${ROUTE.campaignDetails.edit.root}/${id}/landing-page`;
            },
        },
    },
    users: {
        root: '/users',
        status(status = ':status') {
            return `${ROUTE.users.root}/${status}`;
        },
    },
    userInvite: '/user/invite',
    userEdit: (id = ':id', tab = '') => `/user/${id}${tab ? `/${tab}` : ''}`,
    companies: '/companies',
    moduleSettings: '/module-settings',
    moduleSettingsById: (id = ':id') => `/module-settings/${id}`,
    approvalGroups: '/approval-groups',
    regions: '/regions',
    tiers: '/tiers',
    permissions: '/permissions',
    productSpecialisms: '/product-specialisms',
    serviceSpecialisms: '/service-specialisms',
    assetLibrarySetUpListAssets: '/asset-library/set-up/list',
    assetLibrarySetUpTypes: '/asset-library/set-up/types',
    assetLibraryReportingOverview: '/asset-library/reporting',
    manageTemplates: {
        root: '/manage-templates',
        get list() {
            return `${ROUTE.manageTemplates.root}/list`;
        },
        addCampaign: {
            get root() {
                return `${ROUTE.manageTemplates.root}/add-campaign`;
            },
            addSubCampaign: (id = ':id') => `${ROUTE.manageTemplates.addCampaign.root}/to-parent/${id}`,
        },
        editCampaign: {
            root: (id = ':id') => `${ROUTE.manageTemplates.root}/edit-campaign/${id}`,
            templates: (id = ':id') => `${ROUTE.manageTemplates.editCampaign.root(id)}/elements`,
            subCampaigns: (id = ':id') => `${ROUTE.manageTemplates.editCampaign.root(id)}/sub-campaigns`,
        },
        addTemplate: {
            root: (campaignId = ':campaignId') => `${ROUTE.manageTemplates.root}/add-template/${campaignId}`,
            basic: (campaignId = ':campaignId') => `${ROUTE.manageTemplates.addTemplate.root(campaignId)}/basic`,
        },
        editTemplate: {
            root: (campaignId = ':campaignId', templateId = ':templateId') =>
                `${ROUTE.manageTemplates.root}/edit-template/${campaignId}/${templateId}`,
            basic: (campaignId = ':campaignId', templateId = ':templateId') =>
                `${ROUTE.manageTemplates.editTemplate.root(campaignId, templateId)}/basic`,
            extra: (campaignId = ':campaignId', templateId = ':templateId') =>
                `${ROUTE.manageTemplates.editTemplate.root(campaignId, templateId)}/extra`,
        },
    },
    marketingStoreRequest: (id = ':id') => `/marketing-store/request/${id}`,
    marketingStoreSetUpActivities: '/marketing-store/set-up/activities',
    marketingStoreSetUpActivityGroups: '/manage/activity-groups/list',
    manageFonts: '/manage-fonts',
    editCampaignTemplates: (id = ':id') => `/manage-templates/edit-campaign/${id}/elements`,
    editCampaignSubCampaigns: (id = ':id') => `/manage-templates/edit-campaign/${id}/sub-campaigns`,
    manage: {
        root: '/manage',
        toggleGroups: {
            get root() {
                return `${ROUTE.manage.root}/toggle-groups`;
            },
            get list() {
                return `${ROUTE.manage.toggleGroups.root}/list`;
            },
            listStatus: (status = ':status') => `${ROUTE.manage.toggleGroups.root}/list/${status}`,
            get add() {
                return `${ROUTE.manage.toggleGroups.root}/add`;
            },
            edit: (id = ':id', action = ':action') => `${ROUTE.manage.toggleGroups.root}/${id}/action/${action}`,
        },
        toggleOptions: {
            root: (groupId = ':groupId') => `${ROUTE.manage.toggleGroups.root}/${groupId}/options`,
            list: (groupId = ':groupId') => `${ROUTE.manage.toggleOptions.root(groupId)}/list`,
            listStatus: (groupId = ':groupId', status = ':status') =>
                `${ROUTE.manage.toggleOptions.root(groupId)}/list/${status}`,
            add: (groupId = ':groupId') => `${ROUTE.manage.toggleOptions.root(groupId)}/add`,
            edit: (groupId = ':groupId', id = ':id') => `${ROUTE.manage.toggleOptions.root(groupId)}/${id}/edit`,
        },
        get assetRequests() {
            return `${ROUTE.manage.root}/asset-requests`;
        },
        loyaltyBanners: {
            get root() {
                return `${ROUTE.manage.root}/loyalty-banners`;
            },
            get list() {
                return `${ROUTE.manage.loyaltyBanners.root}/list`;
            },
            listStatus: (status = ':status') => `${ROUTE.manage.loyaltyBanners.root}/list/${status}`,
            get add() {
                return `${ROUTE.manage.loyaltyBanners.root}/add`;
            },
            edit: (id = ':id', action = ':action') => `${ROUTE.manage.loyaltyBanners.root}/${id}/action/${action}`,
        },
        loyaltyRewards: {
            get root() {
                return `${ROUTE.manage.root}/loyalty-rewards`;
            },
            get list() {
                return `${ROUTE.manage.loyaltyRewards.root}/list`;
            },
            listStatus: (status = ':status') => `${ROUTE.manage.loyaltyRewards.root}/list/${status}`,
            get add() {
                return `${ROUTE.manage.loyaltyRewards.root}/add`;
            },
            edit: (id = ':id', action = ':action') => `${ROUTE.manage.loyaltyRewards.root}/${id}/action/${action}`,
        },
        loyaltyRewardInstances: {
            get root() {
                return `${ROUTE.manage.root}/program-loyalty-rewards`;
            },
            get list() {
                return `${ROUTE.manage.loyaltyRewardInstances.root}/list`;
            },
            listStatus: (program = ':program', status = ':status') =>
                `${ROUTE.manage.loyaltyRewardInstances.root}/list/${program}/${status}`,
            edit: (id = ':id', action = ':action') =>
                `${ROUTE.manage.loyaltyRewardInstances.root}/${id}/action/${action}`,
        },
        activityGroups: {
            get root() {
                return `${ROUTE.manage.root}/activity-groups`;
            },
            get list() {
                return `${ROUTE.manage.activityGroups.root}/list`;
            },
            listStatus: (status = ':status') => `${ROUTE.manage.activityGroups.root}/list/${status}`,
            get add() {
                return `${ROUTE.manage.activityGroups.root}/add`;
            },
            edit: (id = ':id', action = ':action') => `${ROUTE.manage.activityGroups.root}/${id}/action/${action}`,
        },
        agency: {
            get root() {
                return `${ROUTE.manage.root}/agency`;
            },
            get add() {
                return `${ROUTE.manage.agency.root}/add`;
            },
            edit: (id = ':id') => `${ROUTE.manage.agency.root}/${id}/edit`,
        },
        survey: {
            get root() {
                return `${ROUTE.manage.root}/survey`;
            },
            get list() {
                return `${ROUTE.manage.survey.root}/list`;
            },
            listStatus: (status = ':status') => `${ROUTE.manage.survey.root}/list/${status}`,
            get add() {
                return `${ROUTE.manage.survey.root}/add`;
            },
            edit: (id = ':id', action = ':action') => `${ROUTE.manage.survey.root}/${id}/action/${action}`,
        },
        users: {
            get root() {
                return `${ROUTE.manage.root}/users`;
            },
            list: (status = ':status') => `${ROUTE.manage.users.root}/list/${status}`,
            get invite() {
                return `${ROUTE.manage.users.root}/invite`;
            },
            inviteUser: (id = ':id') => `${ROUTE.manage.users.root}/${id}/invite`,
            get inviteMultiple() {
                return `${ROUTE.manage.users.root}/invite-multiple`;
            },
            get inviteColleague() {
                return `${ROUTE.manage.users.root}/invite-colleague`;
            },
            get inviteColleagueSuccess() {
                return `${ROUTE.manage.users.root}/invite-colleague-thank-you`;
            },
            inviteColleagueView: (id = ':id') => `${ROUTE.manage.users.root}/invited-colleague/${id}`,
            get oldInviteMultiple() {
                return '/user/invite-multiple';
            },
            edit: (id = ':id', tab = ':tab') => `${ROUTE.manage.users.root}/${id}/edit/${tab}`,
        },
        campaigns: {
            get root() {
                return `${ROUTE.manage.root}/campaigns`;
            },
            get list() {
                return `${ROUTE.manage.campaigns.root}/list`;
            },
            // listStatus: (status = ':status') => `${ROUTE.manage.campaigns.root}/list/${status}`,
            move: {
                root: (id = ':id') => `${ROUTE.manage.campaigns.root}/move/${id}`,
                settings: (id = ':id') => `${ROUTE.manage.campaigns.move.root(id)}/settings`,
            },
        },
        featuredCampaigns: {
            get root() {
                return `${ROUTE.manage.root}/featured-campaigns`;
            },
            get list() {
                return `${ROUTE.manage.featuredCampaigns.root}/list`;
            },
            add: (itemType = ':itemType') => `${ROUTE.manage.featuredCampaigns.root}/add/${itemType}`,
            edit: (id = ':id') => `${ROUTE.manage.featuredCampaigns.root}/${id}/edit`,
        },
        socialPosts: {
            get root() {
                return `${ROUTE.manage.root}/social-posts`;
            },
            get list() {
                return `${ROUTE.manage.socialPosts.root}/list`;
            },
            listStatus: (status = ':status') => `${ROUTE.manage.socialPosts.root}/list/${status}`,
            get add() {
                return `${ROUTE.manage.socialPosts.root}/add`;
            },
            edit: (id = ':id') => `${ROUTE.manage.socialPosts.root}/${id}`,
        },
        templateTypes: {
            get root() {
                return `${ROUTE.manage.root}/template-types`;
            },
            get list() {
                return `${ROUTE.manage.templateTypes.root}/list`;
            },
            listStatus: (status = ':status') => `${ROUTE.manage.templateTypes.root}/list/${status}`,
            get add() {
                return `${ROUTE.manage.templateTypes.root}/add`;
            },
            edit: (id = ':id', action = ':action') => `${ROUTE.manage.templateTypes.root}/${id}/action/${action}`,
        },
    },
    manageCampaigns: (status = ':status') => `${ROUTE.manage.root}/campaigns/list/${status}`,
    vendorDashboard: '/',
    identifierBuilderReports: '/reports/identifier-builder',
    certificateBuilderReports: '/reports/certificate-builder',
    customPage: (slug = ':slug') => `/page/${slug}`,
    notifications: '/notifications',
    mergeCompanies: '/merge-companies',
    templateBuilder: TB_ROUTES,
};

export const mapLocaleToMoment = locale => {
    switch (locale) {
        case 'en_GB':
            return 'en-gb';
        case 'es_419':
            return 'es-us';
        case 'es':
            return 'es';
        case 'en_AU':
            return 'en-au';
        case 'en_CA':
            return 'en-ca';
        case 'en_SG':
            return 'en-sg';
        case 'en_ZA':
            return 'en-gb';
        case 'nl':
            return 'nl';
        case 'sv':
            return 'sv';
        case 'fr':
            return 'fr';
        case 'fr_BE':
            return 'fr';
        case 'de':
            return 'de';
        case 'de_CH':
            return 'de-ch';
        case 'it':
            return 'it';
        case 'ja':
            return 'ja';
        case 'zh_Hans':
            return 'zh-cn';
        case 'zh_Hant':
            return 'zh-tw';
        case 'ko':
            return 'ko';
        case 'ru':
            return 'ru';
        case 'tr':
            return 'tr';
        case 'pl':
            return 'pl';
        case 'en_US':
            return 'en';
        case 'pt':
            return 'pt';
        case 'pt_BR':
            return 'pt-br';
        case 'cs':
            return 'cs';
        default:
            return 'en-gb';
    }
};

export const MODULES = {
    ASSET_LIBRARY: 'assetLibrary',
    CAMPAIGN_BUILDER: 'campaignBuilder',
    IDENTIFIER_BUILDER: 'identifierBuilder',
    MARKETING_STORE: 'marketingStore',
    SALES_RESOURCES: 'sales_resources',
    SOCIAL_POSTS: 'social_posts',
    SPIFF: 'SPIFF',
    LOYALTY_PROGRAM: 'loyaltyProgram',
    ONBOARDING: 'onboarding',
    OPPORTUNITY_MANAGEMENT: 'opportunityManagement',
    INCENTIVE: 'incentive',
};

export const FORMAT = {
    DATE: 'DD/MM/YYYY',
    DATE_MONTH_FIRST: 'MM/DD/YYYY',
    DATE_ST: 'Do MMMM YYYY',
    DATE_TIME: 'DD/MM/YYYY [at] h:mm A',
    DATE_TIME_US: 'MM/DD/YYYY [at] h:mm A',
    DATE_API: 'YYYY-MM-DD HH:mm:ss',
    DATE_FULL: 'dddd Do MMMM YYYY, h:mm A',
    DATE_API_SHORT: 'YYYY-MM-DD',
    DATE_US: 'DD/MM/YY',
    DATE_US_MONTH_FIRST: 'MM/DD/YY',
};

export const TEMPLATE_TYPE = {
    ANIMATED_BANNER: 'AnimatedBannerTemplate',
    LANDING_PAGE: 'LandingPageTemplate',
    EMAIL: 'EmailTemplate',
    PDF_TEMPLATE: 'PdfTemplate',
    TWITTER_POST_TEMPLATE: 'TwitterPostTemplate',
    LINKEDIN_POST_TEMPLATE: 'LinkedInPostTemplate',
    FACEBOOK_POST_TEMPLATE: 'FacebookPostTemplate',
    STATIC_BANNER: 'StaticBannerTemplate',
    COBRANDED_PDF: 'CoBrandedPdfTemplate',
};

export const SOCIAL_TEMPLATES = [
    TEMPLATE_TYPE.FACEBOOK_POST_TEMPLATE,
    TEMPLATE_TYPE.LINKEDIN_POST_TEMPLATE,
    TEMPLATE_TYPE.TWITTER_POST_TEMPLATE,
];

export const SOCIAL_NETWORK_TEMPLATE = {
    facebook: TEMPLATE_TYPE.FACEBOOK_POST_TEMPLATE,
    linkedin: TEMPLATE_TYPE.LINKEDIN_POST_TEMPLATE,
    twitter: TEMPLATE_TYPE.TWITTER_POST_TEMPLATE,
};

export const SCHEDULED_TEMPLATES = [TEMPLATE_TYPE.EMAIL, ...SOCIAL_TEMPLATES];

export const USER_ROLE = {
    ROLE_VENDOR: 'ROLE_VENDOR',
    ROLE_REPORT_MANAGER: 'ROLE_REPORT_MANAGER',
    ROLE_DESIGNER: 'ROLE_DESIGNER',
    ROLE_PARTNER: 'ROLE_PARTNER',
    ROLE_TEST_PARTNER: 'ROLE_TEST_PARTNER',
    ROLE_TEST_VENDOR: 'ROLE_TEST_VENDOR',
    ROLE_GLOBAL_ADMIN: 'ROLE_GLOBAL_ADMIN',
    ROLE_CONCIERGE_PARTNER: 'ROLE_CONCIERGE_PARTNER',
    ROLE_CONCIERGE_INTERNAL: 'ROLE_CONCIERGE_INTERNAL',
    ROLE_CONCIERGE: 'ROLE_CONCIERGE',
    ROLE_EA_PARTNER: 'ROLE_EA_PARTNER',
};

export const TEMPLATE_STATUS = {
    LIVE: 'live',
    ARCHIVED: 'archived',
};

export const SOCIAL_POST_STATUS = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const CAMPAIGN_INSTANCE_STATUS = {
    DRAFTED: 'drafted',
    CUSTOMISING: 'customising',
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    COMPLETE: 'complete',
};

export const TEMPLATE_INSTANCE_STATUS = {
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
};

export const ASSET_TYPE_WORKFLOW = {
    CO_BRANDED_PDF: 'co_branded',
    CO_BRANDED_IMAGE: 'co_branded_image',
};

export const CAMPAIGN_INSTANCE_STATUS_MAP = t => ({
    drafted: {
        value: CAMPAIGN_INSTANCE_STATUS.DRAFTED,
        statuses: [CAMPAIGN_INSTANCE_STATUS.DRAFTED, CAMPAIGN_INSTANCE_STATUS.CUSTOMISING],
        label: t('pmApp.campaign.service.CampaignInstanceStatusService.status.drafted'),
    },
    customising: {
        value: CAMPAIGN_INSTANCE_STATUS.DRAFTED,
        statuses: [CAMPAIGN_INSTANCE_STATUS.DRAFTED, CAMPAIGN_INSTANCE_STATUS.CUSTOMISING],
        label: t('pmApp.campaign.service.CampaignInstanceStatusService.status.drafted'),
    },
    in_progress: {
        value: CAMPAIGN_INSTANCE_STATUS.IN_PROGRESS,
        statuses: [CAMPAIGN_INSTANCE_STATUS.IN_PROGRESS, CAMPAIGN_INSTANCE_STATUS.PENDING],
        label: t('pmApp.campaign.service.CampaignInstanceStatusService.status.in_progress'),
    },
    pending: {
        value: CAMPAIGN_INSTANCE_STATUS.IN_PROGRESS,
        statuses: [CAMPAIGN_INSTANCE_STATUS.IN_PROGRESS, CAMPAIGN_INSTANCE_STATUS.PENDING],
        label: t('pmApp.campaign.service.CampaignInstanceStatusService.status.in_progress'),
    },
    complete: {
        value: CAMPAIGN_INSTANCE_STATUS.COMPLETE,
        statuses: [CAMPAIGN_INSTANCE_STATUS.COMPLETE],
        label: t('pmApp.campaign.service.CampaignInstanceStatusService.status.complete'),
    },
});

export const ACTIVITY_REQUEST_STATUS = {
    ACTIVE: 'active',
    PENDING: 'pending',
    REQUESTED: 'requested',
    IN_PLANNING: 'in_planning',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
};

export const ACTIVITY_REQUEST_STATUS_MAP = t => [
    {
        value: ACTIVITY_REQUEST_STATUS.REQUESTED,
        label: t('pmApp.campaign.service.MarketingStoreRequestStatusService.status.requested'),
    },
    {
        value: ACTIVITY_REQUEST_STATUS.IN_PLANNING,
        label: t('pmApp.campaign.service.MarketingStoreRequestStatusService.status.in_planning'),
    },
    {
        value: ACTIVITY_REQUEST_STATUS.IN_PROGRESS,
        label: t('pmApp.campaign.service.MarketingStoreRequestStatusService.status.in_progress'),
    },
    {
        value: ACTIVITY_REQUEST_STATUS.COMPLETED,
        label: t('pmApp.campaign.service.MarketingStoreRequestStatusService.status.completed'),
    },
];

export const REDIRECT_URL_NAME = 'redirect_url';

export const LINK_TYPE = {
    EMAIL: 'email',
    URL: 'url',
    UPLOAD: 'upload',
    ASSET_LIBRARY: 'asset-library',
    SALES_RESOURCE: 'sales-resource',
    // Extra type, not in DB.
    LANDING_PAGE: 'landing',
    // Types used for fallback url.
    FIXED_URL: 'fixed-url',
    PARTNER_URL: 'partner-url',
    // For TemplateBuilder only
    PAGE: 'template-page',
};

export const LINK_URL = {
    COMPANY_EMAIL: 'companyemail',
    PERSONAL_EMAIL: 'personal_email',
    COMPANY_URL: 'companyurl',
    GLOBAL_COMPANYURL: 'global_companyurl',
    PRIVACY_POLICY: 'privacy_policy',
    OTHER: 'other',
    FIXED: 'fixed',
};

export const LINK_OPTION = {
    COMPANY_URL: 'company_url',
    FIXED: 'fixed',
    LANDING_PAGE: 'landing_page',
    OTHER: 'other',
};

export const LINK_TYPE_ASSET_LIBRARY_ALL_ASSETS = 'assetlibrary';

export const COLOUR_TYPE = {
    SWATCH: 'swatch',
    PICKER: 'picker',
};

export const TEMPLATE_COLOUR_TYPE = {
    SWATCH: 'set',
    PICKER: 'color-picker',
};

export const OPPORTUNITY_MODULE_FLOW = {
    // The standard (generalised) flow
    //
    // This is the preferred, and hopefully standard, flow for customers
    // using the Opportunity Module.
    STANDARD: 'standard',

    // The "dedicated" flow
    //
    // This flow was created for Versapay and is relatively bespoke for
    // their needs.
    DEDICATED: 'dedicated',
};

export const SOCIAL_POST_IMAGE_NAME = 'image';

// Copied from https://github.com/angular/angular.js/blob/master/src/ng/directive/input.js#L11-L13
export const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/i;
export const NUMBER_REGEXP = /^\s*(-|\+)?(\d+|(\d*(\.\d*)))\s*$/;
export const URL_REGEXP = /^((ftp|http(s)?):\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?%#[\]@!$&'()*+,;=.]+$/i;
export const DATE_REGEXP = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])( [012]\d:[012345]\d:[012345]\d)?$/i;
export const OPPORTUNITY_UID_REGEXP = /^([a-zA-Z0-9]{15}|[a-zA-Z0-9]{18})$/;
export const DATE_FORMATS_REGEXPS = {
    'Y-m-d': /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])( [012]\d:[012345]\d:[012345]\d)?$/i,
    'm/d/Y': /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}( [012]\d:[012345]\d:[012345]\d)?$/i,
    'm/d/y': /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{2}( [012]\d:[012345]\d:[012345]\d)?$/i,
    'd/m/Y': /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}( [012]\d:[012345]\d:[012345]\d)?$/i,
    'd/m/y': /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{2}( [012]\d:[012345]\d:[012345]\d)?$/i,
};
export const AREA_REGEXP = /^area_(\d+)_/;

// Custom ones.
export const PHONE_REGEXP = /[0-9]/;
export const TIME_REGEXP = /^([01]\d|2[0-3]):?([0-5]\d)$/;
export const LETTERS_REGEXP = /^([A-z ]){2,20}$/;
export const LETTERS_UNDERSCORE_REGEXP = /^[a-zA-Z_]+$/;
export const HEX_COLOUR_REGEXP = /^#[0-9a-f]{6}$/i;

export const NBSP = '\u00A0';

export const APPS = {
    TEMPLATE_BUILDER: 'template-builder',
};

export const PASSWORD_CHANGE_KEY = 'passwordUpdateRequired';
export const PASSWORD_WEAK_ERROR = 'password_too_weak';
